<script setup>
import { orderBy } from 'lodash-es';
import { storeToRefs } from 'pinia';

import { provide, ref } from 'vue';
import { useModal } from 'vue-final-modal';
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';

import { useAuthStore } from '~/auth/stores/auth.store';

import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import HawkDateRangeFilter from '~/common/components/organisms/hawk-filters/hawk-date-range-filter.vue';

import useEmitter from '~/common/composables/useEmitter';
import { useCommonStore } from '~/common/stores/common.store';

import DashboardCreateModal from '~/dashboard/components/create-dashboard/dashboard-create-modal.vue';
import famDefaultDashboardConfig from '~/dashboard/components/create-dashboard/fam-default-dashboard-config.js';

import DashboardExitConfirmationPopup from '~/dashboard/components/dashboard-exit-confirmation-popup.vue';
import DashboardWidgetForm from '~/dashboard/pages/dashboard-widget-form.vue';

import { generateTemplate } from '~/dashboard/print/print.js';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';

import { useFamConstants } from '~/forms-as-module/composables/fam-constants.composable.js';

const props = defineProps({
  options: {
    type: Object,
    default: () => ({
      hide_side_bar: false,
      hide_dashboard_options: true,
      can_modify_resource: false,
      is_stand_alone: false,
      show_top_bar: true,
      is_fam_configure_filter: false,
      dashboard_label: 'Dashboard',
    }),
  },
  templateName: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['allowEdit', 'configure_filter', 'close']);

const DashboardGrid = defineAsyncComponent(() => import('~/dashboard/components/dashboard-grid.vue'));

const getConfig = famDefaultDashboardConfig;

const emitter = useEmitter();

const auth_store = useAuthStore();
const common_store = useCommonStore();
const dashboard_store = useDashboardStore();

const { DASHBOARD_GLOBAL_DATE_FILTERS } = useFamConstants();

const { active_asset } = storeToRefs(common_store);
const { current_dashboard } = storeToRefs(dashboard_store);

const date_filter = ref(null);
const $t = inject('$t');
const $toast = inject('$toast');

const route = useRoute();
const router = useRouter();

const is_editing = ref(false);
const rename = ref(false);
const is_dashboard_saving = ref(false);
const show_sidebar = ref(false);
const is_loading_pdf = ref(false);

const can_modify_reports = computed(() => {
  if (['global', 'organization', 'forms'].includes(dashboard_store.scope))
    return auth_store.check_permission('modify_reports', route.params.asset_id);
  else if (dashboard_store.scope === 'stand_alone')
    return false;

  else return props.options?.can_modify_resource;
});

const all_dashboards = computed(() => {
  return orderBy(dashboard_store.reports, ['created_at'], ['desc']).map((r) => {
    return {
      uid: r.uid,
      label: r.name || 'Dashboard',
      on_click: () => update_query(r.uid),
      has_right_icon: r.default,
    };
  });
});

const current_dashboard_uid = computed(() => current_dashboard.value?.uid || null);

const dashboard_menu_items = computed(() => {
  if (dashboard_store.scope === 'inventory') {
    return [
      {
        label: $t('Edit report'),
        uid: 'edit',
        disabled: !can_modify_reports.value,
        on_click: () => handleReportEdit(),
      },
      {
        label: $t('Duplicate'),
        uid: 'duplicate',
        disabled: !auth_store.check_permission('create_inventory_reports', route.params.asset_id),
        on_click: () => duplicateDashboard(),
      },
      {
        label: $t('Export'),
        uid: 'export',
        on_click: () => emitter.emit('export-inventory-report', current_dashboard.value.uid),
      },
      {
        label: $t('Delete'),
        uid: 'delete',
        on_click: () => dashboardDeleteHandler(),
      },
    ];
  }
  return [
    [
      {
        label: $t('Edit widgets'),
        uid: 'edit',
        disabled: !can_modify_reports.value,
        on_click: () => {
          setIsEditing(true);
        },
      },
      {
        label: $t('Export PDF'),
        uid: 'pdf',
        on_click: () => is_loading_pdf.value = true,
      },
      {
        label: $t('Duplicate'),
        uid: 'duplicate',
        disabled: !can_modify_reports.value,
        on_click: () => duplicateDashboard(),
      },
      {
        label: $t('Settings'),
        uid: 'settings',
        on_click: () => openSettingsModal(),
      },
      {
        label: $t('Delete'),
        uid: 'delete',
        on_click: () => dashboardDeleteHandler(),
      },
    ],
  ];
});

const show_dashboard_header = computed(() => {
  if (dashboard_store.scope === 'inventory')
    return true;
  return !!all_dashboards?.value.length;
});

const show_create_dashboard_button = computed(() => {
  if (dashboard_store.scope === 'inventory')
    return false;
  return can_modify_reports.value;
});

function setIsEditing(value) {
  is_editing.value = value;
  dashboard_store.set_is_editing_dashboard(value);
}

async function toggleDefault(uid) {
  const current_default = dashboard_store.reports.find(o => o.default);
  if (current_default)
    current_default.default = false;
  const dashboard = dashboard_store.reports.find(o => o.uid === uid);
  dashboard.default = !dashboard.default;
  await dashboard_store.update_dashboard(dashboard);
}
function update_query(query = null) {
  is_editing.value = false;
  rename.value = false;

  dashboard_store.reset_print_map();
  dashboard_store.set_active_dashboard(query);

  const dashboard_id = current_dashboard?.value?.uid;
  if (dashboard_id) {
    date_filter.value = current_dashboard.value.selected_date_range;
  }
  router.replace({
    ...route,
    query: {
      ...route.query,
      dashboard: dashboard_id,
    },
  });
}

async function saveChanges() {
  is_dashboard_saving.value = true;
  await dashboard_store.update_dashboard(current_dashboard.value);
  setIsEditing(false);
  $toast({ title: 'Dashboard Saved', text: 'Your changes to the dashboard have been successfully saved.', type: 'success' });

  emit('allowEdit', false);
  is_dashboard_saving.value = false;
}

const { open: openDeletePopup, close: closeDeletePopup, patchOptions } = useModal({
  component: HawkDeletePopup,
});
function dashboardDeleteHandler() {
  patchOptions(
    {
      attrs: {
        header: $t(`Delete ${props.options?.dashboard_label === 'Reports' ? 'Report' : 'Dashboard'}`),
        content: `Are you sure you want to delete ${current_dashboard.value?.name || ''}? This action cannot be undone.`,
        onClose() {
          closeDeletePopup();
        },
        confirm: async () => {
          try {
            await dashboard_store.delete_dashboards([{ uid: current_dashboard.value?.uid }]);
            closeDeletePopup();
            $toast({
              title: `${props.options?.dashboard_label || 'Dashboard'} deleted successfully`,
              text: `Your changes have been saved and your ${props.options?.dashboard_label === 'Reports' ? 'report' : 'dashboard'} is removed from the list`,
              type: 'success',
            });

            update_query(null);
          }
          catch (err) {
            logger.log(err);
            $toast({
              title: 'Something went wrong',
              text: 'Please try again',
              type: 'error',
              position: 'bottom-right',
            });
          }
        },
      },
    },
  );
  openDeletePopup();
}

const { open: openExitConfirmationPopup, close: closeExitConfirmationPopup, patchOptions: patchExitConfirmationPopup } = useModal({
  component: DashboardExitConfirmationPopup,
});

function exitPage(next) {
  patchExitConfirmationPopup({
    attrs: {
      onClose() {
        window.history.forward();
        closeExitConfirmationPopup();
        return false;
      },
      onConfirm: async () => {
        setIsEditing(false);
        closeExitConfirmationPopup();
        next();
      },
    },
  });
  openExitConfirmationPopup();
}

const create_dashboard_modal = useModal({
  component: DashboardCreateModal,
  attrs: {
    onClose() {
      create_dashboard_modal.close();
    },
  },
});

function openCreateDashboardModal() {
  create_dashboard_modal.open();
}

const dashboard_settings_modal = useModal({
  component: DashboardCreateModal,
  attrs: {
    isEditing: true,
    onClose() {
      dashboard_settings_modal.close();
    },
  },
});
function openSettingsModal() {
  dashboard_settings_modal.open();
}

function createFirstDashboard() {
  const dashboard = {
    name: 'Dashboard',
    default: true,
    report_data: dashboard_store.scope === 'forms' ? getConfig() : { ...(current_dashboard.value?.report_data || {}) },
    organization: auth_store?.current_organization.uid,
  };
  dashboard_store.create_dashboard(dashboard);
}
const { open: openWidgetModal, close: closeWidgetModal, patchOptions: patchWidgetModal } = useModal({
  component: DashboardWidgetForm,
});

function openWidgetForm() {
  dashboard_store.create_widget_uid();
  patchWidgetModal({
    attrs: {
      breadcrumbs: dashboard_store.scope === 'inventory'
        ? [
            { uid: '1', label: 'Inventory', on_click: () => emit('close') },
            { uid: '2', label: 'New report' },
          ]
        : null,
      widget_label: dashboard_store.scope === 'inventory' ? 'Add Report' : null,
      onClose() {
        closeWidgetModal();
      },
    },
  });
  openWidgetModal();
}

async function duplicateDashboard() {
  const new_dashboard = {
    name: `${current_dashboard.value.name || active_asset.value?.name || 'Untitled'} -duplicate`,
    default: false,
    report_data: { ...(current_dashboard.value?.report_data || {}) },
    organization: auth_store?.current_organization.uid,
    standard: current_dashboard.value.standard,
  };
  await dashboard_store.create_dashboard(new_dashboard);
  $toast({
    title: $t('Successful!'),
    text: `${$t('Dashboard duplicated successfully')}...`,
    type: 'success',
  });
}

async function printDashboard() {
  if (!Object.values(dashboard_store.print_map || {}).length) {
    $toast({
      title: $t('No data for printing'),
      text: $t('no-data-dashboard-print'),
      type: 'error',
    });

    is_loading_pdf.value = false;
    return;
  }

  const print_meta_data = {
    dashboard_name: current_dashboard.value?.name || 'Untitled',
    asset_name: active_asset.value?.name || 'Untitled',
    asset_address: active_asset.value?.address?.name || '',
    organization_name: auth_store?.current_organization?.name || '',
    organization_image: auth_store?.current_organization?.cover_image || null,
    dashboard_scope: dashboard_store.scope,
    template_name: props.templateName,
    created_by: common_store.get_user_or_team_name(auth_store.logged_in_user_id) || '',
  };

  await new Promise((resolve) => {
    setTimeout(async () => {
      if (localStorage.getItem('log_dashboard_json') === 'true') {
        // eslint-disable-next-line no-console
        console.log({ dashboard_json: dashboard_store.new_print_map });
      }
      await generateTemplate(dashboard_store.print_map, print_meta_data);
      resolve('exporting!');
    }, 3000);
  });

  setTimeout(() => {
    is_loading_pdf.value = false;
  }, 3000);
}

async function initialize(dashboard_id = null) {
  let query = {};
  if (dashboard_store.scope === 'organization') {
    query = {
      organization_only: true,
      standard: false,
    };
  }
  else if (dashboard_store.scope === 'micro_app') {
    query = {
      standard: false,
      asset: route?.params?.asset_id,
    };
  }
  else {
    query = {
      asset: route.params.asset_id,
      ...(dashboard_store.scope === 'schedule'
        ? {
            resource_id: route.params.schedule_id,
            resource_type: 'project_management',
          }
        : {}),
      ...(dashboard_store.scope === 'forms'
        ? {
            resource_id: route.params.template_uid,
            resource_type: 'forms',
          }
        : {}),
      ...(dashboard_store.scope === 'inventory'
        ? {
            resource_type: 'inventory',
          }
        : {}),
    };
  }

  await dashboard_store.set_dashboards(query);
  update_query(dashboard_id);
  if (['forms'].includes(dashboard_store.scope) && !dashboard_store.reports.length)
    createFirstDashboard();
  dashboard_store.set_dashboard_loading(false);
}
watch(() => route.params.asset_id, async (new_val) => {
  if (new_val) {
    dashboard_store.set_dashboard_loading(true);
    await initialize();
  }
});

watch(current_dashboard_uid, (new_val) => {
  if (new_val)
    update_query(new_val);
});

watch(() => current_dashboard.value?.selected_date_range, (new_val, old_val) => {
  if ((old_val?.value !== new_val?.value))
    date_filter.value = new_val;
});

onMounted(async () => {
  dashboard_store.set_dashboard_loading(true);
  await dashboard_store.set_fusion_chart();

  if (props.options.is_stand_alone)
    dashboard_store.set_scope('micro_app');
  else if (route.name === 'pm-dashboard')
    dashboard_store.set_scope('schedule');
  else if (route.name === 'form-template-reports' || route.name === 'fam-overview')
    dashboard_store.set_scope('forms');
  else if (route.name === 'inventory-reports')
    dashboard_store.set_scope('inventory');
  else if (!route.params.asset_id)
    dashboard_store.set_scope('organization');
  else dashboard_store.set_scope('global');

  initialize(route.query.dashboard);
});

function handleReportEdit() {
  dashboard_store.set_edit_widget_data(Object.values(current_dashboard.value.report_data)[0]);
  patchWidgetModal({
    attrs: {
      breadcrumbs: dashboard_store.scope === 'inventory'
        ? [
            { uid: '1', label: 'Inventory', on_click: () => emit('close') },
            { uid: '2', label: 'Edit report' },
          ]
        : null,
      widget_label: dashboard_store.scope === 'inventory' ? 'Edit Report' : null,
      onClose() {
        closeWidgetModal();
      },
    },
  });
  openWidgetModal();
}

async function applyDateFilter(e) {
  date_filter.value = e;
  dashboard_store.update_dashboard({ ...current_dashboard.value, selected_date_range: date_filter.value });
}
onBeforeRouteLeave((to, from, next) => {
  if (is_editing.value)
    exitPage(next);
  else next();
},
);
onBeforeUnmount(() => {
  dashboard_store.reset_print_map();
});

provide('is_loading_pdf', is_loading_pdf);
</script>

<template>
  <div>
    <!-- Loading -->
    <HawkLoader v-if="dashboard_store?.dashboard_loading" />
    <!-- Dashboard -->
    <div v-else class="flex w-full items-start">
      <!-- Dashboard sidebar -->
      <DashboardSidebar v-if="!props.options.hide_side_bar" :show_sidebar="show_sidebar" />
      <!-- Dashboard right section -->
      <div class="w-full">
        <!-- Dashboard header -->
        <!-- Inventory view -->
        <div v-if="!props.options.hide_dashboard_options">
          <HawkPageHeader v-if="show_dashboard_header">
            <template #left>
              <div class="flex items-center gap-4">
                <HawkButton v-if="!props.options.hide_side_bar && active_asset" icon type="text" @click="show_sidebar = !show_sidebar">
                  <IconHawkMenuThree />
                </HawkButton>
                <div class="text-lg font-semibold">
                  {{ $t(options?.dashboard_label || 'Dashboard') }}
                </div>
                <div v-if="all_dashboards?.length" class="flex items-center gap-3">
                  <HawkMenu :items="all_dashboards" position="fixed">
                    <template #trigger="{ open }">
                      <div
                        class="md:w-full lg:w-72 h-9 text-sm font-medium border rounded-lg flex items-center justify-between px-3 no-whitespace-wrap truncate"
                      >
                        <div class="flex items-center gap-2">
                          <HawkText :content="current_dashboard?.name || 'Dashboard'" :length="25" />
                        </div>
                        <div class="flex items-center gap-2">
                          <IconHawkPinnedOne v-if="current_dashboard?.default" class="h-4 w-4 flex-shrink-0" />
                          <IconHawkChevronUp v-if="open" class="text-lg" />
                          <IconHawkChevronDown v-else class="text-lg" />
                        </div>
                      </div>
                    </template>
                    <template #content="{ close }">
                      <div class="w-72 max-h-80 scrollbar text-sm py-1">
                        <div
                          v-for="tab in all_dashboards"
                          :key="tab.label"
                          class="h-10 flex items-center mx-1.5 px-2 hover:bg-gray-50 cursor-pointer rounded-lg text-gray-700 font-medium gap-2"
                          :class="
                            tab.uid === current_dashboard?.uid
                              ? '!text-blue-600 font-semibold'
                              : '!text-gray-700 font-medium'
                          "
                          @click="tab.on_click();close()"
                        >
                          <HawkText :content="tab.label" :length="30" />
                          <IconHawkPinnedOne v-if="tab?.has_right_icon" class="h-4 w-4 flex-shrink-0" />
                        </div>
                      </div>
                    </template>
                  </HawkMenu>
                  <HawkButton
                    v-if="show_create_dashboard_button" icon type="light"
                    color="gray"
                    @click="openCreateDashboardModal()"
                  >
                    <IconHawkPlus />
                  </HawkButton>
                </div>
              </div>
            </template>
            <template #right>
              <div class="flex items-center">
                <HawkButton
                  v-if="dashboard_store.scope === 'inventory' && auth_store.check_permission('create_inventory_reports', route.params.asset_id)"
                  class="mr-4"
                  @click="openWidgetForm()"
                >
                  <IconHawkPlus /> New report
                </HawkButton>
                <HawkMenu v-if="current_dashboard?.uid" :items="dashboard_menu_items" class="ml-auto">
                  <template #trigger>
                    <hawk-button icon type="outlined">
                      <icon-hawk-dots-vertical />
                    </hawk-button>
                  </template>
                </HawkMenu>
              </div>
            </template>
          </HawkPageHeader>
        </div>
        <!-- Forms View -->
        <div v-if="dashboard_store.scope === 'forms' && !is_editing" class="flex items-center justify-between gap-3 px-6 py-4">
          <slot name="top-bar" />
          <div class="flex">
            <hawk-menu
              class="mr-2"
              :items="[...all_dashboards, {
                uid: -1,
                label: 'Create dashboard',
                on_click: () => openCreateDashboardModal(),
              }]"
            >
              <template #trigger="{ open }">
                <HawkButton type="outlined" size="sm">
                  <span class="whitespace-nowrap">
                    {{ current_dashboard?.name || 'Dashboard' }}
                  </span>
                  <IconHawkChevronUp v-if="open" />
                  <IconHawkChevronDown v-else />
                </HawkButton>
              </template>
              <template #content="{ close }">
                <div class="export_menu_items min-w-[190px] my-1">
                  <div
                    v-if="can_modify_reports"
                    class="py-[2px] px-[6px] cursor-pointer"
                    @click="openCreateDashboardModal();close()"
                  >
                    <p
                      class="text-primary-700 text-sm font-medium py-[10px] pr-[10px] pl-[8px] rounded-[6px] hover:bg-blue-50 flex gap-2"
                    >
                      <icon-hawk-plus />
                      {{ $t('New dashboard') }}
                    </p>
                  </div>
                  <div
                    v-for="item in all_dashboards"
                    :key="item.label"
                    class="py-[2px] px-[6px] cursor-pointer"
                    @click="item.on_click(close)"
                  >
                    <p
                      class="text-gray-700 text-sm font-medium py-[10px] pr-[10px] pl-[8px] rounded-[6px] hover:bg-blue-500 hover:bg-opacity-10"
                    >
                      {{ item.label }}
                    </p>
                  </div>
                </div>
              </template>
            </hawk-menu>

            <hawk-menu
              position="fixed"
              :items="[
                ...(can_modify_reports ? [{
                  label: $t('Edit dashboard'),
                  on_click: () => {
                    is_editing = true;
                    emit('allowEdit', true)
                  },
                }] : []),
                ...(options.is_fam_configure_filter
                  ? [{ label: $t('Configure filters'), on_click: () => emit('configure_filter') }]
                  : []),
                { label: $t('Print dashboard'), on_click: () => is_loading_pdf = true },
              ]"
            >
              <template #trigger>
                <HawkButton type="outlined" size="sm" icon>
                  <IconHawkSettingsOne />
                </HawkButton>
              </template>
            </hawk-menu>
          </div>
        </div>

        <!-- Global view -->
        <div v-if="props.options.show_top_bar" :class="{ 'sticky top-[64px] z-10 bg-white': !props.options.is_stand_alone }">
          <div class="flex items-center justify-between p-4 border-b border-solid border-gray-200 w-full ">
            <div v-if="all_dashboards?.length" class="flex items-center gap-3">
              <HawkMenu :items="all_dashboards" position="fixed">
                <template #trigger="{ open }">
                  <div
                    class="md:w-full lg:w-72 h-9 text-sm font-medium border rounded-lg flex items-center justify-between px-3 no-whitespace-wrap truncate"
                  >
                    <div class="flex items-center gap-2">
                      <HawkText :content="current_dashboard?.name || 'Dashboard'" :length="25" />
                    </div>
                    <div class="flex items-center gap-2">
                      <IconHawkPinnedOne v-if="current_dashboard?.default" class="h-4 w-4 flex-shrink-0" />
                      <IconHawkChevronUp v-if="open" class="text-lg" />
                      <IconHawkChevronDown v-else class="text-lg" />
                    </div>
                  </div>
                </template>
                <template #content="{ close }">
                  <div class="w-72 max-h-80 scrollbar text-sm py-1">
                    <div
                      v-for="tab in all_dashboards"
                      :key="tab.label"
                      class="h-10 flex items-center mx-1.5 px-2 hover:bg-gray-50 cursor-pointer rounded-lg text-gray-700 font-medium gap-2 group"
                      :class="
                        tab.uid === current_dashboard?.uid
                          ? '!text-blue-600 font-semibold'
                          : '!text-gray-700 font-medium'
                      "
                      @click="tab.on_click();close()"
                    >
                      <HawkText :content="tab.label" :length="30" />
                      <IconHawkPinnedOne v-if="tab?.has_right_icon" class="h-4 w-4 flex-shrink-0" />
                      <IconHawkPin v-else class="invisible w-4 h-4 handle group-hover:visible flex-shrink-0" @click.stop="toggleDefault(tab.uid)" />
                    </div>
                  </div>
                </template>
              </HawkMenu>
              <HawkButton
                v-if="show_create_dashboard_button" icon type="light"
                color="gray"
                @click="openCreateDashboardModal()"
              >
                <IconHawkPlus />
              </HawkButton>
            </div>
            <div>
              <div v-if="!dashboard_store.reports.length && can_modify_reports">
                <hawk-button type="text" @click="createFirstDashboard">
                  <icon-hawk-plus class="text-primary-700" />
                  <span class="whitespace-nowrap text-primary-700">
                    {{ $t('Create Dashboard') }}
                  </span>
                </hawk-button>
              </div>
              <div v-else-if="is_editing" class="flex items-center">
                <hawk-button v-if="!current_dashboard?.standard" type="text" @click="openWidgetForm">
                  <icon-hawk-plus class="text-primary-700" />
                  <span class="whitespace-nowrap text-primary-700">
                    {{ $t('Add Widget') }}
                  </span>
                </hawk-button>
                <hawk-button
                  v-if="can_modify_reports && dashboard_store.reports.length > 1 && dashboard_store.scope !== 'forms'"
                  class="ml-2"
                  color="error"
                  @click="dashboardDeleteHandler"
                >
                  {{ $t('Delete') }}
                </hawk-button>
                <hawk-button v-if="!current_dashboard?.standard" :loading="is_dashboard_saving" class="ml-2" @click="saveChanges">
                  {{ $t('Save') }}
                </hawk-button>
                <hawk-button v-if="dashboard_store.scope === 'forms' || current_dashboard?.standard" class="ml-2" type="outlined" @click="is_editing = false; emit('allowEdit', false)">
                  {{ $t('Cancel') }}
                </hawk-button>
                <hawk-button
                  v-if="can_modify_reports && dashboard_store.reports.length > 1 && dashboard_store.scope === 'forms'" type="outlined"
                  class="ml-2"
                  @click="dashboardDeleteHandler"
                >
                  <icon-hawk-trash-three class="w-5 h-5" />
                </hawk-button>
              </div>
              <div v-else class="flex items-center">
                <hawk-button v-if="!props.options.is_stand_alone" type="text" @click="is_loading_pdf = true">
                  <icon-hawk-print class="h-5 w-5" />
                </hawk-button>
                <HawkDateRangeFilter
                  v-if="date_filter?.value && date_filter?.value !== 'all_time'"
                  :active-range="date_filter"
                  :date-filters="DASHBOARD_GLOBAL_DATE_FILTERS"
                  @filter-applied="applyDateFilter"
                />
                <HawkMenu v-if="current_dashboard?.uid" :items="dashboard_menu_items" class="ml-auto pl-2">
                  <template #trigger>
                    <hawk-button icon type="outlined">
                      <IconHawkSettingsOne class="h-5 w-5" />
                    </hawk-button>
                  </template>
                </HawkMenu>
              </div>
            </div>
          </div>
        </div>
        <!-- Dashboard Grid -->
        <StandardDashboard v-if="current_dashboard && current_dashboard.standard" :key="current_dashboard.uid" :show_sidebar="show_sidebar" />
        <DashboardGrid v-else-if="current_dashboard" :can-modify-resource="can_modify_reports" :is-editing="is_editing" @open-inventory-form="openWidgetForm" />
      </div>
      <!-- Dashboard print -->
      <HawkExportToast
        v-if="is_loading_pdf"
        :submit="printDashboard"
        :progress_text="$t('Exporting PDF')"
        :completed_text="$t('Exported PDF')"
      />
    </div>
  </div>
</template>

<style lang="scss">
#hawk-dashboard {
  table {
    th {
      font-weight: 700;
      background-color: #374151;
      color: #fff;
    }
  }
}
</style>
